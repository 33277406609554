.root {
  margin-left: 25px;
  box-sizing: border-box;
  .media {
    max-height: 250px;
    max-width: 100%;
  }

  a {
    display: block;
    text-align: center;
  }

  @media print {
    a[href]:after {
      content: none !important;
    }
  }
}
