@import 'src/variables';
// TODO: move
.bad {
  color: $brand-danger;
}

.good {
  color: $brand-primary;
}

.STATUS_SKIPPED {
  text-decoration: line-through;
}
.STATUS_FAILED {
  color: $color-bad;
}

.row {
  display: flex;
  line-height: 20px;
  align-items: center;
}
