.root {
  .absolute {
    display: none;
  }
  &.alt {
    .relative {
      display: none;
    }
    .absolute {
      display: inherit;
    }
  }
  @media print {
    .relative {
      display: none;
    }
    .absolute {
      display: inherit;
    }
  }
}