@import 'src/variables';

.root {
  // background-color: white;
  // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  // margin: auto;
}

.stamp {
  position: absolute;

  top: 0mm;
  left: 4cm;
  width: 2cm;
  overflow: hidden;
  img {
    margin-top: -2mm;
  }
}

.color {
  background-color: $brand-primary !important;
  z-index: -1;
  position: fixed;
  top: 0mm;
  bottom: 0mm;
  left: 0mm;
  width: 5mm;
}

.a4 .newPage {
  @media screen {
    width: 210mm;
    padding: 10mm;
    min-height: 297mm;
    padding: $margins;
  }
}

.newPage {
  @media screen {
    position: relative;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    margin: 20px auto;
  }

  page-break-before: always;
}
