$brand-primary: #15191c;

$brand-secondairy: #777264;
$brand-new: #5191c4;

$color-good: #94bf31;
$color-bad: lighten(#f00, 10%);

$navbar-default-bg: $brand-primary;
$navbar-default-color: #fff;

$navbar-default-link-color: #fff;

$navbar-default-link-active-bg: $brand-secondairy;
$navbar-default-link-active-color: #fff;
$grid-gutter-width: 20px;

$font-size-base: 14px; // unchanged
$blockquote-font-size: $font-size-base;

$selectionColor: mix(white, yellow, 90%);

@import './assets/style/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

$gray-medium: lighten($gray-base, 70%) !default; // #eee
$gray-lightest: lighten($gray-base, 98%) !default; // #eee

$margins: 3.5cm 2cm 3cm 2cm;

$link-color: $gray-light;
