.root {
  width: 100%;
  blockquote {
    margin-bottom: 10px;
  }
  &:first-child {
    margin-top: 0;
  }
  margin-top: 10px;
}
