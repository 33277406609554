@import 'src/variables';

.coverTable {
  // margin-top: 20cm;
  @media screen {
    padding: $margins;
  }
  text-align: right;
  page-break-after: always;
  position: absolute;
  bottom: 0;
  right: 0;
  table {
    display: inline-block;
    width: auto;
    td,
    th {
      padding: 0 5px;
      vertical-align: top;
    }
    tr {
      border: thin solid $gray-lighter;
    }
    tr:nth-child(odd) {
      background-color: $gray-lightest;
    }
  }
}
