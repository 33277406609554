@import 'src/components/mixins';
@import 'src/variables';
.root {
  display: block;
}

.item {
  @include indentedListItem;
  &.unknown {
    height: auto;
  } // > * {
  //   max-height: none;
  // }
  .badge {
    margin-top: -1px;
  }
  .token {
    margin-left: -5px;
    &:first-child {
      margin-left: 0;
    }
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.24);
  }
}

.objectDesc {
  color: $gray-light;
  a {
    color: $gray-light;
  }
  line-height: 1.5em;
  font-size: 1em;
  display: block;
}

.number {
  color: $gray-light;
  font-size: .9em;
  font-style: italic;
}