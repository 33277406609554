@import 'src/variables';

.root {
  background-color: $gray-lighter;
  .detailsPanel {
    dl {
      margin: 10px;
    }
    transition: max-height 500ms ease-in-out;
    max-height: 0;
    overflow: hidden;
    &.open {
      max-height: 300px;
    }
    .completionUrl {
      color: $gray;
      cursor: pointer;
    }
  }

  dd.progressBullet {
    line-height: 22px;
  }
  div.progressBullet {
    display: inline-block;
    position: relative;
    top: 2px;
    margin: 2px 4px;
  }
}
