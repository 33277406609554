@import 'src/variables';

@mixin token {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  line-height: 22px;
}

.hidden {
  display: none !important;
}

.root {
  &.noInput {
    border: none;
    box-shadow: none;
  }
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  padding: 2px 8px;
  .parameter {
    flex: 0 1 auto;
  }
  .btn {
    border: none;
    background: none;
    color: $gray-light;
    padding: 2px 4px;
  }
  .input {
    flex: 1 1 auto;
    @include token;
    display: flex;
    .name {
      opacity: 0.8;
      flex: 0 1 auto;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      display: inline-block;

      i {
        margin-right: 2px;
      }
      padding-left: 8px;
      padding-right: 5px;
    }

    .wrapper {
      flex: 1 1 auto;
      padding-left: 5px;
      input {
        line-height: 20px;
        width: 100%;
        display: block;
        border: none;
        outline: none;
      }
    }
    > * {
      display: inline;
    }
  }
  .parameterItem {
    &.highlighted {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
}

@mixin parameterColor($base) {
  .name {
    background-color: lighten($base, 30%);
  }
  .value {
    color: white;
    background-color: $base;
  }
  &.small {
    .name {
      background-color: $base;
      color: white;
    }
  }
}

.parameter {
  @include token;
  @include parameterColor(lighten($gray-light, 20%));
  &.climate,
  &.climate-cells {
    @include parameterColor($brand-danger);
  }
  &.greenhouse {
    @include parameterColor($brand-primary);
  }
  &.electrical {
    @include parameterColor($brand-warning);
  }
  &.irrigation {
    @include parameterColor(blue);
  }
  &.service {
    @include parameterColor($gray);
  }

  border-radius: 3px;
  overflow: hidden;

  &.small {
    font-size: 0.8em;
    margin: 0 3px;
    .name {
      span {
        display: none;
      }
      text-align: center;
      i {
        margin-right: 0px;
      }
      padding-left: 5px;
      padding-right: 2px;
    }
    .value {
      padding-left: 2px;
    }
  }

  .name,
  .value {
    display: inline-block;
  }
  .name {
    i {
      margin-right: 2px;
    }
    padding-left: 8px;
    padding-right: 5px;
  }
  .value {
    padding-left: 5px;
    padding-right: 5px;
    .removeBtn {
      line-height: 18px;
      padding-right: 0;
      padding-left: 3px;
      border: none;
      background-color: transparent;
      outline: none;
      color: white;
    }
  }
}
