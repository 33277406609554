@import '../../_mixins';
@import '../../../_variables.scss';

@mixin CatalogNode {
  display: flex;
  flex-direction: column;
  &.match {
    background-color: $selectionColor !important;
  }
  .primary {
    flex: 0 0 21px;
    display: flex;
    flex-direction: row;
    > * {
      padding: 0 5px;
    }
    .icon {
      @extend .glyphicon;
    }
    .title {
      flex: 1 1 auto;
    }
  }
}

@for $i from 0 through 10 {
  .depth_#{$i} {
    padding-left: ($i + 1) * 15px;
  }
}

.file {
  @include CatalogNode;
  .icon {
    @extend .glyphicon-file;
    color: $gray-medium;
  }
  color: $gray-light;
  button.toggleExpanded {
    padding-top: 0;
    padding-bottom: 0;
    @extend .btn;
    @extend .btn-sm;
    @extend .glyphicon;
    @extend .glyphicon-chevron-down;
    color: $gray-light;
    background: none;
    border: none;
    line-height: 1;
    top: 0;
  }
  &.expanded {
    button.toggleExpanded {
      @extend .glyphicon-chevron-up;
    }
  }
  &.revisionAvailable {
    color: $gray;
    .icon {
      color: $gray;
    }
  }
  .details {
    details summary {
      text-decoration: underline;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    .previewTask {
      color: $gray-light;
      text-align: center;
    }
    .actions {
      display: block;
      flex: 1 1 auto;
    }
  }
}

.group {
  @include CatalogNode;
  .icon {
    @extend .glyphicon-folder-open;
  }
  &.closed .icon {
    @extend .glyphicon-folder-close;
  }
}
