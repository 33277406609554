@import 'src/variables';
@import 'src/components/mixins';

.list {
  margin-top: 10px;

  .listItem {
    @include indentedListItem;
    .right {
      height: 21px;
    }
  }
}

.unsaved {
  font-weight: bold;
  &::after {
    content: '*';
  }
}

.newBadge {
  background-color: $brand-new !important;
}

.noTemplates {
  text-align: center;
  margin: 10px auto;
}
