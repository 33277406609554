@import '../../_variables.scss';

// FIX: cp-inheritance
@mixin token {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  line-height: 22px;
}

@mixin parameterColor($base) {
  .name {
    background-color: lighten($base, 30%);
  }
  .value {
    color: white;
    background-color: $base;
  }
  &.small {
    .name {
      background-color: $base;
      color: white;
    }
  }
  &.inline {
    display: inline-flex;
  }
}

.parameter {
  @include token;
  @include parameterColor(lighten($gray-light, 20%));
  &.C,
  &.V {
    @include parameterColor($brand-danger);
  }
  &.K {
    @include parameterColor($brand-primary);
  }
  &.E {
    @include parameterColor($brand-warning);
  }
  &.W {
    @include parameterColor(blue);
  }
  &.S {
    @include parameterColor($gray);
  }

  border-radius: 3px;
  overflow: hidden;

  &.small {
    font-size: 0.8em;
    margin: 0 3px;
    .name {
      span {
        display: none;
      }
      text-align: center;
      i {
        margin-right: 0px;
      }
      padding-left: 5px;
      padding-right: 2px;
    }
    .value {
      padding-left: 2px;
    }
  }

  .name,
  .value {
    display: inline-block;
  }
  .name {
    i {
      margin-right: 2px;
    }
    padding-left: 8px;
    padding-right: 5px;
  }
  .value {
    padding-left: 5px;
    padding-right: 5px;
    .removeBtn {
      line-height: 18px;
      padding-right: 0;
      padding-left: 3px;
      border: none;
      background-color: transparent;
      outline: none;
      color: white;
    }
  }
}
