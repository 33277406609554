@import 'src/variables';

.root {
  display: inline-block;
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
  text-align: center;
}

.small {
  padding: 1px 4px 2px;
  border-radius: 3px;
  font-size: 0.8em;
  min-width: 20px;
}

.opened {
  background-color: $color-bad;
}
.closed {
  background-color: $color-good;
}
.create {
  background-color: $gray-light;
}
