@import 'src/variables';

@media print {
  @page {
    margin: $margins;
  }
}
@media screen {
  body.paper {
    margin-top: 51px + 10px;
    background-color: $gray-lighter;
  }
}
