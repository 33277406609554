@import '../../variables';

.followBtn {
  font-size: 21px;
  line-height: 30px;
  vertical-align: center;
}
.root {
  h2 > i {
    font-size: smaller;
    color: $gray-medium;
  }
}
