@import 'src/variables';
button.root {
  background-color: black;
  border: solid thin white;
  border-radius: 17px;
}

// TODO: move
.bad {
  color: $color-bad;
}

.pending {
  color: $brand-warning;
}

.good {
  color: $color-good;
}
