@import 'src/variables';

.root {
  margin: 10px auto 15px;
  width: 100%;
  text-align: center;
  font-size: .95em;
  color: $gray-light;
  font-style: italic;
  button {
    padding : 0;
    vertical-align: baseline  !important;
  }
}