@import 'src/variables';

.formContainer{
   textarea {margin: 5px 0;}
   height: 0;
   overflow: hidden;
   transition: height 500ms ease;
}

div.expanded {
  height: 200px;
}

.feedbackBtn {
  color: $brand-warning;
  border: solid thin $brand-warning;
  background-color: transparent;
}
