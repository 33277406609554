@import 'src/variables';

.root {
  .search {
    margin: 10px auto;
  }

  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      flex: 0 0 auto;
    }
  }
}