@mixin label {
  // font-family: monospace;
  border: solid thin black;
  &.back {
    border: none;
  }
  position: relative;
  margin: auto;
  margin-bottom: -1px;
  page-break-inside: avoid;
  > * {
    position: absolute;
  }
  .backCode {
    font-size: 6mm;
    top: 5mm;
    left: 5mm;
  }
}

.sep {
  color: lightgrey;
}

// @mixin cropMarks {
//   border: solid white 8px;
//   box-sizing: content-box;
//   background-color: white;
//   z-index: 2;
//   &:before,
//   &:after {
//     position: absolute;
//     content: '';
//     border: solid black;
//     z-index: 1;
//   }
//   &:before {
//     top: -32px;
//     bottom: -32px;
//     left: 0;
//     right: 0;
//     border-width: 0 1px;
//     z-index: 1;
//   }
//   &:after {
//     left: -32px;
//     right: -32px;
//     top: 0;
//     bottom: 0;
//     border-width: 1px 0;
//     z-index: 1;
//   }
// }

.klein {
  @include label;
  // @include cropMarks();
  height: 25mm;
  width: 120mm;

  svg {
    top: 2mm;
    right: 2mm;
    width: 20mm;
    height: 20mm;
  }

  .artikelName {
    font-size: 6mm;
    top: 10mm;
    left: 2mm;
    line-height: 6mm;
    overflow-wrap: break-word;
    width: 95mm;
  }
  .artikelCode {
    font-size: 6mm;
    font-weight: bolder;
    top: 2mm;
    left: 2mm;
  }

  .unsalable {
    font-style: italic;
    font-size: 3.5mm;
    top: 4mm;
    right: 25mm;
    color: darkred;
  }
}

.middel {
  $top: 4mm;
  @include label;
  height: 60mm;
  width: 150mm;

  svg {
    top: 5mm + $top;
    right: 5mm;
    width: 30mm;
    height: 30mm;
  }

  .artikelName {
    top: 25mm + $top;
    left: 6mm;
    right: 40mm;
    font-size: 8mm;
  }
  .artikelCode {
    font-size: 15mm;
    text-align: center;
    top: 0mm + $top;
    left: 5mm;
    // right: 6mm;
  }

  .locationDesc {
    font-size: 10mm;
    text-align: center;
    top: 2mm + $top;
    left: 5mm;
    // right: 6mm;
  }

  .unsalable {
    font-style: italic;
    font-size: 5mm;
    top: 40mm + $top;
    right: 10mm;
    color: darkred;
  }
}

.a4 {
  @include label;
  height: 200mm;
  border: none;
  svg {
    top: 100mm;
    right: 0;
    width: 100%;
    height: 80mm;
  }

  .artikelName {
    font-size: 12mm;
    top: 40mm;
    left: 3mm;
    right: 3mm;
    text-align: center;
  }
  .artikelCode {
    font-size: 25mm;
    top: 3mm;
    left: 3mm;
    right: 3mm;
    text-align: center;
  }

  &.hasLocationDescr {
    .artikelCode {
      // top: 30mm;
    }
  }
}
