@import 'src/variables';

.highlighted {
  a {background-color: $brand-primary !important };
  a {color: white !important};
}

.light {
  color: $gray-light;
}
