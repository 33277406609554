@import './libs.scss';

// cause text in code blocks to not be wrapped and preserves formatting
// specifically for codeblocks in tickets
pre code {
  white-space: inherit;
}

#root {
  background-color: $gray-lightest;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  height: 100vh;
}
#nav {
  flex: 0 0;
  margin: 0;
}
#content {
  flex: 1 1; /* This makes the content area flexible and take up remaining space */
  padding: 0;
  flex-direction: column;
  > * {
    flex: 1 1;
  }
  > :last-child {
    flex: 0 0;
    z-index: 99; // on top of content shadow
  }
}

footer {
  background-color: black;
  &,
  a,
  button {
    color: white !important;
  }
  @media (max-width: $screen-sm-max) {
    text-align: center;
  }
}

@media print {
  * {
    color: inherit !important;
    background: inherit !important;
  }
}

a.navbar-brand img {
  display: inline;
  margin: -15px 3px -10px;
  height: 18px;
}

.container {
  width: auto;
  max-width: 680px;
  @media print {
    max-width: none;
    padding: 0;
  }
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[name='username'] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-signin input[name='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// notification heading
@media (min-width: $screen-sm-min) {
  h1.project {
    display: inline-block;
  }
}

@mixin pull-right() {
  @extend .pull-right;
}

%pl {
  @include pull-right();
}

.pl {
  @extend %pl;
}

a.notifications {
  @media (min-width: $screen-sm-min) {
    // @extend .pl;
  }
  margin: 30px;
  .hover {
    display: none;
  }
  &:hover .hover {
    display: inline-block;
  }
  &:hover *:first-child {
    display: none;
  }
  i {
    // @extend .fa;
  }
}

@media (max-width: $screen-sm-max) {
  a.notifications {
    text-align: center;
    display: block;
  }
}

@mixin icon() {
  @extend .glyphicon;
  display: none;
  margin-left: 5px;
  color: $gray-light;
  &:hover {
    color: $brand-primary;
  }
}

/**********************************************************
 * Treeview
 */

//controls
div.controls {
  @include make-sm-column(12);
  padding: 10px; // @include icon()
  button {
    padding: 0;
    font-size: 1em;
  }
  @mixin icon() {
    @extend .glyphicon;
    span {
      font-family: $font-family-base;
    }
    color: lighten($brand-secondairy, 20%);
    &:hover {
      color: $brand-primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .expand-all {
    @include icon();
    @extend .glyphicon-resize-full;
  }
  .collapse-all {
    @include icon();
    @extend .glyphicon-resize-small;
  }
  @media (min-width: $screen-sm-min) {
    span {
      display: none;
    }
    font-size: 18px;
    margin-left: -70px;
    position: sticky;
    top: 50px;
    width: auto;
  }
  @media (max-width: $screen-xs-max) {
    margin-top: -10px;
    text-align: center;
    button {
      display: inline-block;
      width: 49%;
    }
  }
}

$indent-base: 15px;
$indent-width: 20px;
@mixin indent($lvl) {
  .head .name {
    margin-left: $indent-base + $lvl * $indent-width;
  }
}

@mixin sub-list-group() {
  @extend .list-group;
  ul {
    overflow: visible;
    padding-left: 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -10px;
  }
}

@mixin sub-list-group-item() {
  @extend .list-group-item;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top-radius: 0 !important;
  border-bottom-radius: 0 !important;
}

ul.file-view {
  &.animate ul {
    @include transition(max-height 0.4s ease);
  }
  /* layout */
  @include sub-list-group();
  .group {
    @include sub-list-group();
    @extend .list-group-item;
    .group,
    .file,
    .empty-file {
      @include sub-list-group-item();
    }
    .group,
    .file,
    .empty-file {
      border-radius: 0 !important;
    }
  }
  /* group specific */
  .group {
    > .head > .name {
      margin-bottom: 10px;
      display: block;
      cursor: pointer;
      span {
        width: 20px;
        @extend .glyphicon;
        @extend .glyphicon-folder-open;
      }
    }
    > ul {
      transition: 1s;
    }
    &.collapsed {
      > .head > .name span {
        @extend .glyphicon-folder-close;
      }
      > .head > ul {
        max-height: 0 !important;
        overflow: hidden;
      }
    }
  }
  /* file specific */
  .file,
  > .empty-file {
    &,
    a {
      color: red;
      color: $text-muted;
    }
    > .head .name span {
      @extend .glyphicon;
      @extend .glyphicon-file;
    }
    list-style-type: none;
    padding-left: 15px;
    padding-right: 15px;
    a:hover {
      text-decoration: none;
    }
    a {
      cursor: default;
    }
  }
  .file {
    overflow: hidden;
    @include transition(margin 0.4s ease);
    @include transition(padding 0.4s ease);
    @include transition(box-shadow 0.4s ease);
    &.expanded {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      z-index: 10;
      margin-left: -7px;
      margin-right: -7px;
      padding-left: 22px;
      padding-right: 22px;
      .body {
        height: 250px;
      }
    }
    .toggle-expand {
      @extend .glyphicon;
      @extend .glyphicon-triangle-left;
      display: none;
      @extend .pull-right;
      @extend .text-muted;
      padding-top: 1px;
      padding-bottom: 2px;
    }
    &.downloaded .toggle-expand {
      display: inline-block;
    }
    &.expanded .toggle-expand {
      @extend .glyphicon-triangle-bottom;
    }
    &.available {
      label span {
        color: black;
        cursor: pointer;
      }
    }
    &.downloaded {
      &,
      a {
        color: black;
        cursor: pointer;
      }
    }
    .new-window {
      @include icon();
      @extend .glyphicon-share;
    }
    .download {
      @include icon();
      @extend .glyphicon-cloud-download;
    }
    .info {
      @extend .text-muted;
      @extend .pull-right;
    } // Hover state
    &:hover,
    &:focus {
      text-decoration: none;
      color: $list-group-link-hover-color;
      background-color: $list-group-hover-bg;
      .download,
      .new-window {
        display: inline-block;
      }
    }
    .download-progress {
      @extend .pull-right;
    }
    .body {
      transition: height 0.5s;
      height: 0;
    }
  }
  .empty-file {
  }
  /* indentation */
  li ul li {
    @include indent(1);
  }
  li ul li ul li {
    @include indent(2);
  }
  li ul li ul li ul li {
    @include indent(3);
  }
  li ul li ul li ul li ul li {
    @include indent(4);
  }
  li ul li ul li ul li ul li ul li {
    @include indent(5);
  }
}

div.well.terms {
  height: 500px;
  overflow: scroll;
  h2 {
    font-size: 18px;
  }
}

/* DOCS */

div.video-filetree {
  position: relative;
  video {
    position: absolute;
    top: 7%;
    left: 6%;
    width: 88%;
  }
}

.download-progress {
  span {
    @extend .hidden-xs;
  }
  display: inline-block;
  float: right;
}

.download-progress {
  height: 5px;
  width: 100px;
  margin: 6px 10px;
  float: right;
  .progress-bar {
    transition: none;
  }
}

.file-preview {
  text-align: center;
  > img {
    @extend .img-thumbnail;
    display: block;
    margin: auto;
  }
  margin-bottom: 10px;
}

.spinner {
  display: block;
  text-align: center;
}

.danger,
.danger a,
.dropdown-menu > li.danger > a {
  color: $brand-danger;
}

.listHeader {
  display: flex;
  align-items: flex-end;
  span {
    flex: 1 1 auto;
    line-height: 20px;
  }
  button {
    flex: 0 0 auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media print {
  a[href]:after {
    content: none;
  }
  #footer {
    display: none;
  }
  body {
    padding-top: 0;
    background: red;
  }
  *[role='dialog'] {
    display: none;
  }
}
