@import 'src/variables';

@mixin btn($color) {
  color: $color;
  background-color: $gray-lighter;
  font-size: 1.2em;
  padding: 2px 10px;

  &.selected {
    background: $color;
    background-color: lighten($color, 30%);
    color: darken($color, 10%);
  }
}

.control {
  margin-bottom: 0;
  button {
    height: 31px;
    width: 40px;
    text-align: center;
  }
}

.completed {
  &,
  &:hover,
  &:focus {
    background: $color-good;
    background-color: lighten($color-good, 30%);
    color: darken($color-good, 10%);
  }
}
