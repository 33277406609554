.root {
  width: 16px;
  height: 32px;
  margin: 0 2px;
  position: relative;
  top: -4px;
}

.btn {
  border: none;
  padding: none;
  background: none;
}
