@import 'src/variables';

.root {
  box-sizing: border-box;
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;

  background-color: rgba(1, 1, 1, 0.7);

  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: top;
  align-content: flex-start;
  align-items: flex-start;
}

.groupTitle {
  color: white;
}

@mixin actions {
  flex: 0 0 auto;
  display: flex;
  button i {
    color: $gray-lighter;
    text-shadow: 1px 1px 10px black;
  }
}

.actionsAbove {
  @include actions();
  :global {
    .spacer,
    .closes {
      flex: 0 0 35px;
    }
    .up {
      flex: 1 1 auto;
    }
  }
}
.container {
  position: relative;
  flex: 0 0 auto;
  margin: 0 auto;
  width: 100%;
}
.fieldBox {
  position: relative;
  display: block;
  // background-color: white;
  // border: thin solid $list-group-border;
  // border-radius: $list-group-border-radius;
}

.actionsBelow {
  @include actions();
  :global {
    .down {
      flex: 1 1 100%;
    }
  }
}

.feedback {
  flex: 1 0 auto;
}
