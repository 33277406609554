@import 'src/variables';

.noEntities {
  display: block;
  text-align: center;
  color: $gray;
  font-style: italic;
  font-size: 0.8em;
  margin-bottom: 30px;
}
