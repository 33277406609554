@import 'src/variables';

.root {
  min-height: 25px;
  height: 100%;
  width: 100%;
  input {
    display: inline;
    background: none;
    border: none;
    &,
    &:focus {
      box-shadow: none;
    }
  }
}
.tokenRoot {
  > div {
    display: inline-block;
  }
  button {
    background: none;
    border: none;
    vertical-align: top;
    font-size: 1.2em;
    color: $gray-light;
    margin-left: -2px;
    margin-top: -3px;
  }
}
