@import 'src/variables';

@mixin btn($color){
  color: $color;
  background-color: $gray-lighter;
  font-size: 1.2em;
  padding: 2px 10px;

  &.selected {
    background: $color;
    background-color: lighten($color, 30%);
    color: darken($color, 10%)
  }
}

.control {
  :global button.good {
    @include btn($color-good);
  }
  :global button.bad {
    @include btn($color-bad);
  }
}
