@import 'src/variables';

.root {
  .toc {
    @media (min-width: $screen-md-min) {
      margin-top: 70px;
      position: sticky;
      top: $navbar-height + 10px;
    }
  }
}

.markdown {
  .link {
    display: none;
    font-size: 0.6em;
  }
  *:hover > .link {
    display: inline;
  }
}
