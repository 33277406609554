@import 'src/assets/style/font-awesome/scss/_variables.scss';
@import 'src/variables';
.root {
  top: 60px;
  right: 20px;
  width: 400px;
  bottom: 60px;
  overflow: scroll;
}

.linked {
  i:before {
    content: $fa-var-chain-broken;
  }
  &:hover {
    i:before {
      content: $fa-var-link;
    }
  }
}

.unlinked {
  i:before {
    content: $fa-var-link;
  }
  &:hover {
    i:before {
      content: $fa-var-chain-broken;
    }
  }
}

.newBadge {
  background-color: $brand-new !important;
}
