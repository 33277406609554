@import 'src/variables';

.root {
  display: block;
  padding: 10px 10px;

  border: thin solid $gray-lighter;
  background-color: $gray-lightest;
  border-radius: 3px;
  span {
    vertical-align: top;
  }
  .status {
    cursor: pointer;
    position: relative;
    top: -1px;
  }
  .main {
    height: 40px;
  }
  hr {
    margin: 8px 0;
  }
}
.number,
.title {
  font-size: 14px;
  width: 270px;
  display: inline-block;
}
.number {
  display: inline-block;
  width: 30px;
}

.right {
  float: right;
}
.date {
  width: 110px;
}
.date,
.assignee {
  cursor: pointer;
  input {
    margin-top: -5px;
  }
  i {
    display: none;
  }
  &:hover i {
    display: inline;
  }
  .token {
    display: inline-block;
    // white-space: nowrap;
    > span {
      display: inline-box;
      width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.assignee {
  width: 150px;
  &:hover .token > span {
    width: 100px;
  }
  i {
    vertical-align: top;
  }
}
.comment {
  margin-top: -2px;
}
