@import 'src/components/mixins';

.root {
  @include indentedListItem;

  .badge {
    margin-top: -1px;
    margin-bottom: -6px;
    // margin-left: -5px;
    // margin-right: 5px;
  }
  .token {
    margin-left: -5px;
    &:first-child {
      margin-left: 0;
    }
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
      0 1px 1px 0 rgba(0, 0, 0, 0.24);
  }
}
