@import "variables";

ul.mde-suggestions {
  position: absolute;
  min-width: 180px;
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  cursor: pointer;
  background: #fff;
  border: 1px solid $mde-border-color;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(27, 31, 35, .15);

  li {
    padding: 4px 8px;
    border-bottom: 1px solid #e1e4e8;

    &:first-child {
      border-top-left-radius: $mde-border-radius;
      border-top-right-radius: $mde-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $mde-border-radius;
      border-bottom-left-radius: $mde-border-radius;
    }

    &:hover, &[aria-selected=true] {
      color: $mde-white-color;
      background-color: $mde-selected-color;
    }
  }
}
