@import '../../variables';

.table {
  td.path {
    text-align: right;
  }
  .separator {
    margin: auto 4px;
    color: $gray-light;
  }
  tr.selected td {
    background-color: $gray-lighter;
  }
  .stockUnsalable a {
    color: $brand-danger;
    text-decoration: line-through;
  }
  .locationUnsalable .lastLocation {
    color: $brand-danger;
    text-decoration: line-through;
  }
  tr.inputRow {
    padding-left: 0;
    padding-right: 0;
    td {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
