@import 'src/components/mixins';
.root {
  @include indentedListItem;
  .badge {
    margin-top: -1px;
  }
  .departmentToken {
    margin-bottom: -8px !important;
  }
  .users {
    display: inline;
    .token {
      margin-left: -5px;
      &:first-child {
        margin-left: 0;
      }
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
        0 1px 1px 0 rgba(0, 0, 0, 0.24);
    }
  }
  .due {
    display: inline;
    margin-right: 5px;
  }
}

.number {
  color: $gray-light;
  font-size: 0.9em;
  font-style: italic;
}
