@import 'src/variables';
.detailsContainer {
  margin-top: -20px;
  padding-top: 20px;
  background-color: white;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.noDescription {
  display: block;
}

.ticketDetails {
  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    button {
      margin-top: 4px;
    }
  }
  .overDue {
    color: $brand-danger;
  }
}

.messageForm {
  button {
    margin-top: 5px;
  }
}

.statusCtrl,
.involvedUsers {
  display: block;
}

@media print {
  .detailsContainer button {
    display: none;
  }
}

.number {
  color: $gray-light;
  @media print {
    color: $gray-light !important;
  }
  font-size: 0.9em;
  font-style: italic;
}
