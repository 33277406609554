@import '../../variables';
$carretRight: '\f0da';

.root {
  padding-left: 0;
  ul {
    padding-left: 10px;
  }
  li {
    list-style: none;
  }
  li::before {
    font-family: FontAwesome;
    content: $carretRight;
    margin-right: 5px;
  }
  .active {
    > a,
    > span {
      font-weight: bold;
    }
    &::before {
      font-family: FontAwesome;
      content: $carretRight;
      margin-right: 5px;
    }
  }
  :global {
    i.fa {
      color: $gray-medium;
    }
  }
}
