@import 'src/variables';

.sheet {
  background: white;
  padding: 10px;
}

button.nextMeeting {
  padding-left: 0;
}

.metaform {
  display: flex;
  flex-wrap: wrap;

  .nameField,
  .dateField {
    cursor: pointer;
    i {
      display: none;
    }
    &:hover i {
      display: inline;
    }
  }
}

.field {
  flex: 0 0 33.3%;
  margin-bottom: 20px;
  > * {
    display: block;
  }
  label {
    font-weight: 300;
    color: $gray-light;
  }
  .userToken {
    display: inline-block;
    margin-right: 5px;
  }
}

.label {
  height: 20px;
  border-radius: 11px;
  color: white;
  font-size: 12px;
  margin: 0 3px;
  padding: 2px 8px;
  display: inline-block;
}

$sheetPadding: 10px;

.sheet {
  position: relative;
  padding: $sheetPadding;
  min-height: 300px;

  .overlay {
    position: absolute;
    // box-shadow: 0 0 10px rgb(255, 0, 0);

    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    .blur {
      .frame {
        padding: 20px 0 20px;
        margin: 50px 140px;

        // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        background-color: white;
        box-shadow: 0 0 10px 10px white;
        // border-radius: 6px;

        display: flex;
        flex-direction: column;
        button.start {
          margin: 10px 20% 20px;
          box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
        }
        a {
          text-align: center;
        }
      }
      backdrop-filter: blur(2px);
      position: absolute;

      top: -15px;
      bottom: -15px;
      left: -15px;
      right: -10px;
    }
    background-color: rgba(255, 255, 255, 0.541);
  }

  textarea.sheetTextarea {
    width: 100%;
    border: none;
    resize: none;
    overflow: auto;
    outline: none;
    font-family: monospace;
  }
  .section {
    position: relative;
    button.removeSection {
      display: none;
      position: absolute;
      border: none;
      background: none;
      color: $gray-light;
      right: 20px;
      font-size: 1.2em;
    }
    .labels {
      display: none;
      position: absolute;
      border: none;
      background: none;
      color: $gray-light;
      right: 40px;
      font-size: 1.2em;
      margin-top: -2px;
    }
    &:hover {
      button.removeSection,
      .labels {
        display: block;
      }
    }
    h3 {
      margin-top: 10px;
      input {
        outline: none;
        border: none;
      }
    }
    .handle {
      position: absolute;
      background: $gray;
      height: 15px;
      width: 6px;
      left: -3px -$sheetPadding;
      top: 6px;
    }
  }
}

.ticketItem {
  span {
    color: $brand-primary;
    text-decoration: underline;
  }
  .washedStatus {
    background-color: $gray-lighter;
  }
}
