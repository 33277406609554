@mixin token {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  line-height: 22px;
}

.parameter {
  @include token;

  color: white;

  padding-left: 5px;
  padding-right: 5px;

  border-radius: 4px;
  overflow: hidden;

  > span {
    opacity: 0.8;
  }

  .removeBtn {
    line-height: 18px;
    padding-right: 0;
    border: none;
    background-color: transparent;
    outline: none;
    margin-left: 4px;
  }
}
