@import 'src/variables';

:global {
  .dropdown-menu {
    max-height: 300px;
    overflow: hidden;
  }
}

.highlight {
  background-color: #f5f5f5;
}
