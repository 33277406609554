@import 'src/variables';
.event {
  margin-top: 10px;
  .light {
    display: block;
    color: $gray; // font-weight: light;
    a {
      color: $gray;
    }
    i {
      display: inline-block;
      width: 25px;
      text-align: center;
      color: $gray-light;
    }
  }
  hr {
    margin: 5px;
  }
}

.eventBox {
  display: flex;
  flex-direction: row;
  .userToken {
    flex: 0 0 1;
    margin-top: 10px;
    margin-right: 2px;
    > div {
      position: sticky;
      position: -webkit-sticky;
      top: 60px;
    }
  }
  .content {
    flex: 1 1;
  }
}

:global(.print) {
  .eventBox {
    display: none;
  }
}
@media print {
  .eventBox {
    display: none;
  }
}
