@import 'src/variables';

:global {
  .dropdown-menu {
    max-height: 300px;
    overflow: hidden;
  }
}

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  padding: 3px 8px;
  > * {
    flex: 0 1 auto;
  }
  .wrapper {
    flex: 1 1 80px;
    padding-left: 5px;
    input {
      line-height: 20px;
      width: 100%;
      display: block;
      border: none;
      outline: none;
    }
  }
}

.highlight {
  background-color: #f5f5f5;
}
