.forgotButton {
  display: block;
}

.passwordWrapper{
  position: relative;
  .toggleVisibility {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 12px 14px;

    z-index: 10;
    background: none;
    border: none;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
  }
}