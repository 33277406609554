@import '../../variables';

table.stockTable {
  tbody tr {
    span {
      color: $gray-light;
    }
    button {
      display: none;
    }
    &.unsalable {
      text-decoration: line-through;
      color: $color-bad;
      span {
        color: lighten($color-bad, 10%);
      }
    }
  }
  tbody tr:hover button {
    display: block;
  }
  tr td {
    border: none;
  }
}

.root {
  h6 {
    border-bottom: solid thin black;
  }
}
