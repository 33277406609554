.root {
  a {
    word-break: break-all;
  }
  table {
    width: auto;
  }
  img {
    max-height: 300px;
  }
}