@import 'src/variables';

.highlighted {
  a {
    background-color: $brand-primary !important;
    color: white !important;
  }
  span {
    color: $gray-light;
  }
}
.item span {
  color: $gray-light;
}
