@import '../../variables';

.spread_buttons {
  display: flex;

  > * {
    flex: 1;
  }
  button + button {
    margin-left: 15px;
  }
}

.root {
  background-color: $gray-lighter;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.25);
}
.path {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display an ellipsis (...) for overflow */
  direction: rtl; /* Right-to-left direction to create a left-side ellipsis */
}

.danger {
  color: $brand-danger;
  border: thin solid $brand-danger;
  font-weight: bold;
  padding: 2px 5px;
  display: block;
  border-radius: 5px;
}
