@import 'src/variables';

/* FIXME: split into column, header, footer and page modules */
.columnLayout {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.fabs {
  display: flex;
  flex-direction: column;
  padding: 10px;

  a {
    margin: 5px;
    font-size: 14px;
    text-align: center;
    border-radius: 22px;
    padding: 10px;
    background: black;
    color: white;
    display: block;
    box-sizing: content-box;

    width: 14px;
    height: 14px;
  }
  a:hover {
    color: white;
  }
}

// used as were moving from the old bs containers to the new multicolumn layout
.tempTransitionContainer {
  margin-top: 20px;
}

.column {
  display: block;
  position: relative;
  &:last-child {
    min-width: min(480px, 100vw);
  }
  background: #ffffff;

  /* Column shadow */
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);

  @media (max-width: $screen-xs-max) {
    // there is only a single column on mobile so make it use all width
    width: 100%;
  }
  .inner {
    padding: 15px 10px 15px;
    position: sticky;
    top: 0;
    overflow: hidden;
    min-height: calc(100vh - 50px);
  }
}

.filterBtn {
  margin-right: 10px;
}

.filterNav {
  margin-top: -20px;
  background-color: $gray-lighter;
}

.hidden {
  height: 0;
}

.unsaved a {
  font-weight: bold;
  &::after {
    content: '*';
  }
}

.userToken {
  top: -5px;
  margin-bottom: -10px;
}

.changelogMarkdown {
  h1 {
    font-size: 1.5em;
    font-weight: bolder;
  }
}

nav.nav {
  margin-bottom: 0;
  z-index: 999;
  min-height: auto;
}
