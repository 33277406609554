@use 'sass:math';
@import 'src/variables';
@import 'src/assets/style/font-awesome/scss/font-awesome';

.root {
  position: relative;
  .complete {
    path {
      fill: $color-good;
    }
    circle {
      stroke: $color-good;
    }
  }
  &.subtle {
    .complete {
      path {
        fill: $gray-medium;
      }
      circle {
        stroke: $gray-medium;
      }
    }
  }
  path {
    fill: $gray-light;
    stroke: none;
  }
  &.dashed circle {
    stroke-dasharray: 15% 15%;
  }
  circle {
    stroke: $gray-light;
    stroke-width: math.div(4px, 24);
    fill: transparent;
  }
  i {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
  }
  svg {
    position: relative;
  }
  &.failed {
    path {
      fill: $color-bad;
    }
    circle {
      stroke: $color-bad;
    }
  }
}
