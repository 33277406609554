@import '../../variables';

.root {
  min-width: 100%;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  td,
  th {
    padding: 0 8px;
  }
  td > div {
    height: 24px;
  }
  tr {
    height: 24px;
  }

  .leafLink {
    display: inline-flex;
    align-items: flex-center;
    
    img {
      margin-right: 4px;
    }
  }
  .nameMissing {
    opacity: .5;
    min-width: 20px;
  }
}



tr.selected {
  background: rgba(0, 0, 0, 0.06);
  font-weight: bolder;
  a {
    color: $gray;
  }
}
tr.above {
  border-top: 1px solid black;
}
tr.below {
  padding: 0;
  border-bottom: 1px solid black;
}
tr.inside {
  background: rgba(0, 0, 0, 0.06);
}

.btn {
  border: none;
  background: none;
  padding: 0;
  i {
    width: 16px;
    font-size: 12px;
    margin: 0 2px;
    text-align: center;
    line-height: 24px;
    color: $gray-medium;
  }
}
.tree {
  height: 24px;
  display: flex;
  .trailIcon {
    width: 16px;
    height: 32px;
    margin: 0 2px;
    position: relative;
    top: -4px;
  }
}
