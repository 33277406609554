@import 'src/variables';

.rootTitle {
  button {
    // display: none;
    margin: 0px 0;
  }
  &:hover button {
    display: inline-block;
  }
}
.groupTitle {
  button {
    // display: none;
    margin: -5px 0;
  }
  &:hover button {
    display: inline-block;
  }
}
.selected {
  background-color: $selectionColor;
  box-shadow: 0 0 10px 10px $selectionColor;
  .valueField {
    background-color: $selectionColor;
  }
}
.valueField {
  padding-top: 3px;
  padding-bottom: 3px;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  outline: none;
}

.expandedValueField {
  // @use .valueField;
  margin: 20px 0 20px;
  // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.titleBox {
  order: 0;
  flex: 1 1 auto;
  align-self: center;
}

.descriptionBox {
  margin-bottom: 20px;
}

.controlBox {
  order: 0;
  flex: 0 0 auto;
  align-self: center;
}

.attachmentSection {
  margin-top: 20px;
  width: 100%;
  > div {
    text-align: center;
  }
}

.attachment {
  padding: 5px;
  border: thin solid $gray-lighter;
  display: inline-block;
  margin: 10px auto;
  div {
    text-align: right;
  }
  p {
    margin: 0;
  }
  embed {
    align: center;
    max-width: 100%;
    max-height: 300px;
  }
}

.accept {
  :global {
    .panel {
      border-color: $color-good;
      border-width: medium;
    }
  }
}

.reject {
  :global {
    .panel {
      border-color: $color-bad;
      border-width: medium;
    }
  }
}

.printedCheckpoint {
  .good {
    color: $color-good;
    @media print {
      color: $color-good !important;
    }
  }
  .bad {
    color: $color-bad;
    @media print {
      color: $color-bad !important;
    }
  }
  .missing {
    color: $gray-light;
    @media print {
      color: $gray-light !important;
    }
  }
}
.printedCheckpoint {
  padding: 5px;
  &:nth-child(odd) {
    background-color: $gray-lightest !important;
  }
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
  h4 {
    font-size: 1.2em;
  }
  em {
    display: block;
    font-size: 0.8em;
    color: $gray-light;
  }
  > div {
    flex-direction: row;
    display: flex;
    flex: 1;
  }
  > div > div {
    flex: 1 1;
  }
  .answer {
    flex: 0 0 100px;
  }
  .attachmentSection {
    margin-top: -20px;
    display: flex;
    .meta {
      display: none;
    }
    .attachment {
      margin: 5px;
      width: 100%;
      flex: 0 0 25%;
    }
    .attachmentActions {
      display: none;
    }
  }
}

printedCheckpoint {
  margin-top: 2cm;
}

.timeline {
  display: block;
  background-color: $gray-lightest;
  margin: -15px;
  padding: 15px;
}
