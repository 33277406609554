@import '../../_variables';

.statusDescription {
  color: $gray-light;
  display: block;
  line-height: 15px;
}
.progress {
  display: inline-block;
}

.bytes {
  font-size: 0.8em;
  color: $gray-light;
}

.root {
  padding-bottom: 20px;
}
