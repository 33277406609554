@import 'src/variables';

.highlighted {
  a {
    background-color: $brand-primary !important;
  }
  a {
    color: white !important;
  }
}

.filterTags {
  display: flex;
  align-items: center;
  // span { flex: 0}
  // .param
  > * {
    flex: 1; // 1 auto;
  }
  span {
    flex: 0 1 auto;
  }
}

.light {
  color: $gray-light;
}
