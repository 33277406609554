.infoSection {
  h3 {
    font-size: 2.2em;
    margin-top: 0;
    small {
      display: block
    }
  }
  > button, > a {
    margin: 16px 5px;
  }
}
