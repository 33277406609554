
@import 'src/variables';

.root {
  display: inline-block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  // margin: 10px;
  span {
    line-height: 40px;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 24px;

    opacity: .8;
    font-weight: bold;
  }
}

@mixin small {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  span {
    line-height: 20px;
    font-size: 12px;

  }
}

.small {
  @include small()
}

.collapse {
  @media (max-width: $screen-xs-max) {
    @include small();
  }
}

.textRoot {
  .name {
    margin-left: 2px;
    vertical-align: top;
    display: inline-block;
  }
}

.deselectBtn {
  background: none;
  border: none;
  vertical-align: top;
  font-size: 1.2em;
  color: $gray-light;
  margin-left: -2px;
  margin-top: -3px;
}