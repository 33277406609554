@import '../_variables.scss';

$icon-font-path: '../../fonts/bootstrap/';
@import 'src/assets/style/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss';
@import 'src/assets/style/bootstrap-sass/assets/stylesheets/bootstrap/_buttons.scss';
@import 'src/assets/style/bootstrap-sass/assets/stylesheets/bootstrap/_glyphicons.scss';

@mixin indentedListItem {
  display: flex;
  // height: 41px;
  overflow: hidden;
  .badge {
    flex: 0 0 30px;
    margin-left: -10px;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
    text-align: center;
    background: none;
    padding: initial;
  }
  .token {
    margin-bottom: -6px;
  }
  .title {
    flex: 1 1 auto;
    // margin-left: 50px;
    .controls {
      display: none;
      &:hover {
        display: inline-block;
      }
      float: right;
      // flex: 0 0 auto;
    }
    i {
      color: $gray-medium;
    }
  }
  .right {
    float: right;
    margin-left: 5px;
    // flex: 0 0 auto;
    button {
      padding: 4px 12px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
    > span {
      font-size: 0.8em;
    }
    // margin-right: 5px;
    color: $gray-light;
  }
}
